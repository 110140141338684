<template>
  <div class="container">
    <h3>{{astronauts.length}} people are in space right now</h3>
    <p class="text-left">This page shows all people who are currently in space (including International Space Station, Tiangong space station and other missions).</p>
    <p class="text-left">The list is updated on a daily basis, check out new updates!</p>
    <p class="text-left">You can also subscribe to <a href="https://telegram.me/people_in_orbit_bot">our Telegram bot</a> to get notified if this list changes 🙂</p>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Location</th>
          <th scope="col">Country</th>
          <th scope="col">Photo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="astronaut in astronauts" v-bind:key="astronaut.name"> 
          <th scope="row">{{astronaut.name}}</th>
          <td>{{astronaut.station}}</td>
          <td>{{astronaut.country}}&nbsp;{{astronaut.flag}}</td>
          <td v-if="astronaut.hasOwnProperty('image')">
            <a :href="astronaut.wiki_url"><img :src="get_image_url(astronaut.image.src)" :width="astronaut.image.width" :height="astronaut.image.height"></a>
          </td>
        </tr>
      </tbody>
    </table> 
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'AstronautsPage',
    metaInfo: {
      title: 'People In Space Now',
    },
    data() {
      return {
        astronauts: null,
      };
    },
    created: function() {
      axios
        .get('/data/astronauts')
        .then(res => {
          this.astronauts = res.data.items;
        })
    },
    methods: {
      get_image_url(url) {
        return 'https:' + url;
      }
    }
  }
</script>

<style>
  h3 {
    margin-bottom: 5%;
    font-weight: bold;
    font-size: 2.5rem;
  }
</style>
